<template>
    <div class="max_div">
        <div class="no_max_div">
            <div class="top_div">
                <!-- <div class="select_div">
                    <el-select v-model="selectData" :size="tabsize" @change="selectChange" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.val"
                        :label="item.key"
                        :value="item.val"
                       >
                        </el-option>
                    </el-select>
                </div> -->

                <!-- <div class="button_div">
                    <el-button :size="tabsize" @click="test">打印或保存</el-button>
                </div> -->
            </div>

            <div class="bottom_div">
                <div class="button_div">
                    <el-button :size="tabsize" :disabled="!cname"  @click="test">打印或保存</el-button>
                </div>

                <div class="top_div1">
                    <div class="title_div">
                        {{ echarts4.qdcname}}
                        <br>{{ echarts4.cname }}
                        <br>视力筛查总结
                    </div>

                    <div class="title_bottom_div">
                        <!-- 第二师教育局智能视力管理系统 -->
                        筛查时间：{{ echarts4.scrtime ? $dateFormat(+echarts4.scrtime,'yyyy年MM月dd日'): '' }}
                    </div>
                </div>

                <div class="text_div">
                    <div class="text_title">
                        1、报告概述
                    </div>
                    <div class="text_info">
                        根据国家教育部、卫健委颁发的《关于加强儿童青少年近视防控工作的指导意见》、教育局《关于开展2023年中小学视力监测及数据上报工作的通知》、《关于开展2023年全县中小学视力筛查工作实施方案的通知》等文件要求，在教育局、卫健局及学校领导的关心指导下，开展{{echarts4.cname}}筛查工作，本次筛查工作得到学校领导、老师、校医的全程、严格的监督，符合视力筛查的各项要求。
                    </div>
                </div>
                <div class="text_div">
                    <div class="text_title">
                        2、报告说明
                    </div>

                    <div class="text_info">
                        本次报告分析维度基于学校、年级（年龄）、学段、性别、裸眼视力、矫正视力、球镜、散光、屈光参差等维度进行分析，便于学校了解年级（年龄）与视力、性别与视力的相关性，除了分析学龄段视力程度的分布之外，本报告重点分析严重散光、屈光参差可能带来的弱视风险，便于学校、家长及时排查风险，避免耽误孩子矫正、治疗；同时还对矫正率、矫正不良率、远视储备不足率等指标进行分析。
建议后续筛查可以根据当前数据进行定制化的筛查，在学生视力健康上能够做到更完善、精准、针对性，做到真正解决问题，帮助家长学会视力保护知识，帮助孩子保护好视力，让每一个孩子的未来都有更多选择
                    </div>
                </div>

                <div class="text_div">
                    <div class="text_title">
                        3、分析参考值
                    </div>
                    <div class="text_title_min">
                        （一）视力评价标准
                    </div>
                    <div class="text_info">
                        视力状况评价依据教育部统一评价标准，评价的是孩子裸眼视力的能力，不代表孩子的实际视力能力、屈光度。
                    </div>
                    <div class="border_div">
                        <table id="customers" class="customers1">
                            <tr>
                                <td>视力评价</td>
                                <td>评价标准(6岁以上）</td>
                                <td>评价标准(5-6岁）</td>
                                <td>评价标准(4-5岁）</td>
                            </tr>
                            <tr>
                                <td>正常视力</td>
                                <td>双眼视力≥5.0以上</td>
                                <td>双眼视力≥4.9</td>
                                <td>双眼视力≥4.8</td>
                            </tr>
                            <tr>
                                <td>轻度视力不良</td>
                                <td>单眼4.9，另一只眼≥4.9</td>
                                <td>单眼4.8，另一只眼≥4.8</td>
                                <td>单眼4.7，另一只眼≥4.7</td>
                            </tr>
                            <tr>
                                <td>中度视力不良</td>
                                <td>单眼4.6、4.7、4.8，另一只眼≥4.6</td>
                                <td>单眼4.5、4.6、4.7，另一只眼≥4.5</td>
                                <td>单眼4.4、4.5、4.6，另一只眼≥4.4</td>
                            </tr>
                            <tr>
                                <td>重度视力不良</td>
                                <td>单眼4.5及以下</td>
                                <td>单眼4.4及以下</td>
                                <td>单眼4.3及以下</td>

                            </tr>
                        </table>
                    </div>
                    <div class="text_title_min">
                        <div class="texta">
                            （二）筛查性近视评价标准
                        </div>
                        <div class="textb">
                            筛查性近视近代表筛查时反应的学生屈光度，不作为真实视力状况及配镜依据
                        </div>
                    </div>
                    <div class="border_div">
                        <table id="customers" class="customers1">
                            <tr>
                                <td>筛查性近视</td>
                                <td>标准</td>
                            </tr>
                            <tr>
                                <td>满足条件</td>
                                <td>裸眼视力：低于正常视力 <br>等效球镜：左右眼等效球镜小于等于-0.50D；等效球镜=球镜+柱镜/2</td>
                            </tr>
                        </table>
                    </div>

                  
                    <div class="text_title_min">
                        （三）近视程度评价标准
                    </div>
                    <table id="customers" class="customers2">
                            <tr>
                                <td>高度远视</td>
                                <td>中度远视</td>
                                <td>轻度远视</td>
                                <td>正视</td>
                                <td>轻度近视</td>
                                <td>中度近视</td>
                                <td>高度近视</td>
                                <td>超高度近视</td>
                            </tr>
                            
                            <tr>
                                <td>>5.0D</td>
                                <td>5.0D≥>3.25D</td>
                                <td>≤3.0D</td>
                                <td>0.25D≥>-0.25D</td>
                                <td>-0.5D≥>-3D</td>
                                <td>-3.25D≥>-6D</td>
                                <td>-6.25D≥>-9D</td>
                                <td>≤-9.25D</td>
                            </tr>
                    </table>
                    <div class="text_title_min">
                        （四）散光程度评价标准
                    </div>
                    <table id="customers" class="customers2">
                            <tr>
                                <td>无散光</td>
                                <td>低度散光</td>
                                <td>中度散光</td>
                                <td>重度散光</td>
                                <td>高度散光</td>
                            </tr>
                            
                            <tr>
                                <td>≥-0.25D</td>
                                <td>≥-1D</td>
                                <td>-1.25≥-2D</td>
                                <td>-2.25D≥-3D</td>
                                <td>≤-3.25D</td>
                            </tr>
                    </table>
                    <div class="text_title_min">
                        （五）屈光参差评价标准
                    </div>
                    
                    <table id="customers" class="customers2">
                            <tr>
                                <td>参差程度</td>
                                <td>标准</td>
                            </tr>

                            <tr>
                                <td>屈光参差</td>
                                <td>左右眼球镜相差≥1.5D，或左右眼柱镜相差≥1D</td>
                            </tr>

                            <tr>
                                <td>严重屈光参差</td>
                                <td>双眼等效球镜相差2.50D以上</td>
                            </tr>
                    </table>
                    <div class="text_title_min">
                        <div class="texta">
                            （六）弱视风险评价标准
                        </div><br>
                        <div class="textb">
                            弱视风险评价便于对未发现弱视的学生近视风险提示，提醒学校及家长及时干预，结果以医院实际诊断为准。
                        </div>
                    </div>
                    <div class="border_div">
                        <table id="customers" class="customers1">
                            <tr>
                                <td>弱视评价</td>
                                <td>标准</td>
                            </tr>
                            <tr>
                                <td>弱视</td>
                                <td>单眼矫正视 &lt; 4.6
                                 </td>
                            </tr>
                            <tr>
                                <td>弱视风险</td>
                                <td> 单眼裸眼视力&lt;4.2，且:存在球镜>5.00D,或球镜&lt;-8.00D,或柱镜&lt;-5.00D</td>
                            </tr>
                        </table>
                    </div>

                    <div class="text_title_min">
                        <div class="texta">
                            （七）远视储备评价标准
                        </div>
                        <div class="textb">
                            远视储备评价作为未近视预防的核心指标，提醒未近视但临近近视的学生提前开展预防，需以医院散瞳数据为准。
                        </div>
                    </div>
                    <div class="border_div">
                        <table id="customers" class="customers1">
                            <tr>
                                <td>年龄</td>
                                <td>1-3岁</td>
                                <td>4-5岁</td>
                                <td>6-7岁</td>
                                <td>8岁</td>
                                <td>9岁</td>
                                <td>10岁</td>
                            </tr>
                            <tr>
                                <td>远视储备</td>
                                <td>+2.50D-+3.00D</td>
                                <td>+2.00D-+2.50D</td>
                                <td>+1.75D-+2.00D</td>
                                <td>>+1.50D</td>
                                <td>>+1.25D</td>
                                <td>>+1.00D</td>
                            </tr>

                            <tr>
                                <td>年龄</td>
                                <td>11岁</td>
                                <td>12岁</td>
                                <td>13岁</td>
                                <td>14岁</td>
                                <td>15岁</td>
                                <td>16岁以上</td>
                            </tr>
                            <tr>
                                <td>远视储备</td>
                                <td>>+0.75D</td>
                                <td>>+0.5D</td>
                                <td>>+0.25D</td>
                                <td>>+0.00D</td>
                                <td>/</td>
                                <td>/</td>
                            </tr>
                        </table>
                    </div>
                    <div class="text_title_min">
                        <div class="texta">
                            （八）偏差声明
                        </div>
                        <div class="textb">
                            学校筛查受筛查现场光线环境、学生筛查前用眼状态、配合度等影响，并存在筛查时学生之间的干扰，筛查工作也存在追求效率的情况，并非严格标准的视力检查，故而存在一定偏差，其中也会存在裸眼视力±2 行的情况，均属于正常现象。严格的视力检查应去医院眼科进行，本分析报告基于筛查结果进行。
                        </div>
                    </div>

                    <div class="echarts_list">
                        <div class="text_title">
                            4、整体筛查情况分析
                        </div>
                        <div class="border_div">
                            <table id="customers" class="echarts4">
                                <tr>
                                    <td>班级数量</td>
                                    <td>完成数量</td>
                                    <td>完成率</td>
                                    <td>总人数</td>
                                    <td>已筛查</td>
                                    <td>未筛查</td>
                                    <td>完成率</td>
                                </tr>
                                <tr>
                                    <td>{{echarts4.num  ? echarts4.num : 0}}</td>
                                    <td>{{echarts4.anum  ? echarts4.anum : 0}}</td>
                                    <td>{{echarts4.percentage  ? echarts4.percentage : 0}}%</td>
                                    <td>{{echarts4.sum  ? echarts4.sum : 0}}</td>
                                    <td>{{echarts4.examinenum ? echarts4.examinenum : 0}}</td>
                                    <td>{{echarts4.notnum  ? echarts4.notnum : 0}}</td>
                                    <td>{{echarts4.examinerate  ? echarts4.examinerate : 0}}%</td>
                                </tr>
                                <tr>
                                    <td>正常视力人数</td>
                                    <td>异常视力人数</td>
                                    <td>整体不良率</td>
                                    <td>筛查性近视人数</td>
                                    <td>筛查性近视率</td>
                                </tr>
                                <tr>
                                    <td>{{echarts4.num3  ? echarts4.num3 : 0}}</td>
                                    <td>{{echarts4.num4  ? echarts4.num4 : 0}}</td>
                                    <td>{{echarts4.entirety  ? echarts4.entirety : 0}}%</td>
                                    <td>{{echarts4.num5 || 0}}</td>
                                    <td>{{echarts4.num6 || 0}}%</td>
                                </tr>
                               
                            </table>
                        </div>
                        <div class="text_title">
                            5、各学校筛查完成情况
                        </div>
                        <div id="echarts5">

                        </div>  

                        <div class="text_title">
                            6、全区视力异常率
                        </div>
                        <div id="echarts6">

                        </div>

                        <div class="text_title">
                            7、各学段视力分布
                        </div>
                        <div id="echarts7">

                        </div>

                        <div class="text_title">
                            8、各学校视力状况分布
                        </div>
                        <div id="echarts8">

                        </div>

                        <div class="text_title">
                            9、各学校筛查性近视率
                        </div>
                        <div id="echarts9">

                        </div>

                        <div class="text_title">
                            10、各年级视力异常率
                        </div>
                        <div id="echarts10">

                        </div>


                        <div class="text_title" style="margin-top:250px">
                            11、各年级筛查性近视
                        </div>
                        <div id="echarts11">

                        </div>
                    </div>

                    <div class="text_title">
                        12、综合建议
                    </div>
                    <div class="text_info" style="margin-top:5px">
                        综上所述，我们发现有以下问题：<br/>
                        1、裸眼视力随着年级的增加有非常明显的下降趋势；
                        <br/>2、远视储备正常和正视的学生比例随着年级提升下降非常明显，其中一年级阶段远视储备基本消耗殆尽，也是近视发生率最高的阶段；
                        <br/>3、近视的度数随着年级的提升，增加非常明显，高年级的中高度近视比重增加；
                        <br/>4、有不少孩子存在屈光参差、弱视、严重散光等问题，如果不及时处理，会造成近视加速发展；
                        <br/>5、假性近视：筛查中发现裸眼视力正常但屈光度较低的人数40%以上，这个现象是学生视力发展为近视的征兆，需要重点开展培训、教育，提前干预，避免近视加重；
                        <br/>6、矫正问题：近视但未矫正的比例大于矫正人数，矫正后裸眼视力不足5.0的超过50%

                        <div class="text_title_min">
                            建议:
                        </div>
                        1、根据当前学生筛查结果和数据情况，将评价结果和风险提示通知到每一个家长；
                        <br/>2、由学校出面组织，据学生现状，定期开展视力健康教育的线上或者线下培训，针对性的为正视、远视储备不足的学生提供预防近视培训；为远视、严重散光、弱视风险、屈光参差的学生提供检查建议、矫正治疗建议；针对矫正不良、未矫正的孩子家长进行专项科普，提高科学矫正率；为近视的孩子提供防控的具体方法和技巧。
                        <br/>3、鼓励近视发展比较快的孩子定期检查视力，录入电子视力档案，分析近视发展，参加学校统一组织的视力防控科普小组。
                        <br/>4、建立学校学生视力数据监测平台，对近视发展快、有较严重的视力风险的学生进行预警，通知家长进行重点关注。
                        <br/>5、后续筛查增加矫正方案的数据采集；根据视力现状分析矫正方案、是否矫正等信息，提供更合理科学的矫正方案和建议
                    </div>

                    <div class="bottom_div1">
                        <!-- 第二师教育局智能视力管理系统 -->
                        {{ echarts4.qdcname }}<br/>
                        {{ $dateFormat(+echarts4.scrtime,'yyyy-MM-dd') }}
                        <!-- 技术支持：电子视力档案 -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { 
        initBar,
        initPie,
        initBar2,
        initBar3,
        initBar4,
        initLine,
        initBarz
    } from './echarts'
    import dayjs from "dayjs"

    export default {
        mounted(){
            this.cname = this.$route.query.cname
            console.log(':disabled="cname"==>',this.cname);
            this.screeningAnalyst()
            this.performance()
            this.abnormal()
            this.visionDistribution()
            this.svisionDistribution()
            this.myopiarate()
            this.clvision()
            this.classmyopia()
        },
        data(){
            return {
                options:[
                    {
                        key:'A',
                        val:'1'
                    },
                    {
                        key:'B',
                        val:'2'
                    },
                    {
                        key:'C',
                        val:'3'
                    },
                    {
                        key:'D',
                        val:'4'
                    }
                ],
                cname:'',
                echarts4:{},                
                echarts5:{},
                echarts6:[],
                echarts7:{},
                echarts8:{},
                echarts9:{},
                echarts10:{},
                echarts11:{},
                echarts12:{},
                selectData:'',
                newDate:dayjs(new Date()).format('YYYY年MM月DD日')
            }
        },
        methods:{
            selectChange(item){
                console.log('选择的是==>',item);
            },
            test(){
                window.print()
            },
            screeningAnalyst(){
                this.$api.exInfo.screeningAnalyst({
                    cname:this.cname
                }).then((res)=>{
                    console.log('echarts4==>',res);
                    if(res.status == 1){
                        this.echarts4 = res.obj
                    }else{
                        this.$alertError('echarts4数据异常')
                    }
                }).catch((error)=>{

                })
            },

            performance(){
                this.$api.exInfo.performance({
                    cname:this.cname
                }).then((res)=>{
                    console.log('echarts5==>',res);
                    if(res.status == 1){
                        this.echarts5 = res.obj
                        initBar('echarts5',this.echarts5)
                    }else{
                        this.$alertError('echarts5数据异常')
                    }
                }).catch((error)=>{

                })
            },
            abnormal(){
                this.$api.exInfo.abnormal({
                    cname:this.cname
                }).then((res)=>{
                    console.log('echarts6==>',res);
                    if(res.status == 1){
                        this.echarts6 = res.obj
                        initPie('echarts6',this.echarts6)
                    
                    }else{
                        this.$alertError('echarts6数据异常')
                    }
                }).catch((error)=>{

                })
            },
            visionDistribution(){
                this.$api.exInfo.visionDistribution({
                    cname:this.cname
                }).then((res)=>{
                    console.log('echarts7==>',res);
                    if(res.status == 1){
                        this.echarts7 = res.obj
                        initBar2('echarts7',this.echarts7)
                    }else{
                        this.$alertError('echarts7数据异常')
                    }
                }).catch((error)=>{

                })
            },
            svisionDistribution(){
                this.$api.exInfo.svisionDistribution({
                    cname:this.cname
                }).then((res)=>{
                    console.log('echarts8==>',res);
                    if(res.status == 1){
                        this.echarts8 = res.obj
                        initBar3('echarts8',this.echarts8)
                    }else{
                        this.$alertError('echarts8数据异常')
                    }
                }).catch((error)=>{

                })
            },
            myopiarate(){
                this.$api.exInfo.myopiarate({
                    cname:this.cname
                }).then((res)=>{
                    console.log('echarts9==>',res);
                    if(res.status == 1){
                        this.echarts9 = res.obj
                        initBarz('echarts9',this.echarts9)
                    }else{
                        this.$alertError('echarts9数据异常')
                    }
                }).catch((error)=>{

                })
            },
            clvision(){
                this.$api.exInfo.clvision({
                    cname:this.cname
                }).then((res)=>{
                    console.log('echarts10==>',res);
                    if(res.status == 1){
                        this.echarts10 = res.obj
                        initLine('echarts10',this.echarts10)
                    }else{
                        this.$alertError('echarts10数据异常')
                    }
                }).catch((error)=>{

                })
            },
            classmyopia(){
                this.$api.exInfo.classmyopia({
                    cname:this.cname
                }).then((res)=>{
                    console.log('echarts11==>',res);
                    if(res.status == 1){
                        this.echarts11 = res.obj
                        initBar4('echarts11',this.echarts11)
                    }else{
                        this.alertError('echarts11数据异常')
                    }
                }).catch((error)=>{

                })
            }
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        }
    }
</script>

<style scoped>
    
    .max_div{
        display: flex;
        justify-content: center;
        overflow: auto;
        height: auto;
        overflow: hidden;
        /* overflow-y: visible; */
        /* size: A4 portrait */
    }
    .no_max_div{
        width:794px;
        display: flex;
        flex-direction: column;
        overflow-y: visible
    }
    .top_div{
        width: 100%;
        display: flex;
        padding: 0 70px;
        flex-direction: row;
        justify-content: space-between;
    }
    .top_div1{
        width: 100%;
        height: 1024px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .bottom_div{
        margin-top: 2vw;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 70px;
        padding-bottom: 5vw;
    }
    .title_div{
        font-size: 48px;
        text-align: center;
    }
    .title_bottom_div{
        margin-top: 17vw;
        font-size: 24px;
        text-align: center;
    }
    .text_div{
        /* margin-top: 20px; */
    }
    .echarts_list{
        /* margin-top: 20px; */
    }
    .text_title{
        margin-top: 20px;
        font-size: 16px;
        font-family: 黑体;
    }
    .text_title_min{
        font-size: 16px;
        margin-top: 15px;
        font-family: 楷体;
    }
    .texta{
        font-size: 16px;
        margin-top: 15px;
        font-family: 楷体;
    }
    .textb{
        margin-top: 5px;
        font-family: 仿宋;
        font-size: 16px;
    }
    .text_info{
        margin-top: 5px;
        font-family: 仿宋;
        font-size: 16px;
    }
    
    .border_div{
        margin-top: 10px;
    }
    #customers{
        width: 100%;
        border-collapse: collapse;
    }

    #customers td, #customers th {
        border: 1px solid #ddd;
        padding: 8px;
        font-size: 10px;
    }
    .customers1 tr>:first-child{
        width: 30%;
    }
    .customers2{
        margin-top: 10px;
        font-size: 10px;
    }
    .customers2 td, .customers2 th {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }
    .echarts4{
        font-size: 12px;
        text-align: center;
    }
    #echarts5{
        width: 100%;
        height: 400px;
    }
    #echarts6{
        width: 100%;
        height: 300px;
    }
    #echarts7{
        width: 100%;
        height: 200px;
    }
    #echarts8{
        width: 100%;
        height: 400px;
    }
    #echarts9{
        width: 100%;
        height: 400px;
    }
    #echarts10{
        width: 100%;
        height: 400px;
    }

    #echarts11{
        width: 100%;
        height: 400px;
    }
    .bottom_div1{
        width: 100%;
        text-align: right;
        margin-top: 50px;
    }
    .button_div{
        position: absolute;
        width: 794px;
        display: flex;
        justify-content: end;
        padding: 0 70px;
    }
    @media print {
        .button_div { display: none }
    }
    .customers1 tr :first-child{
        width: 130px;
    }
    /* td{
        text-align: center;
    } */
</style>