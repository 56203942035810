// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// import geoJson from '../../../assets/json/china.geo.json'
// 引入柱状图图表，图表后缀都为 Chart
import {
    LineChart,
    BarChart,
    PieChart,
    ScatterChart,
    EffectScatterChart
} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    DatasetComponentOption,
    GeoComponent,
    TransformComponent,
    MarkLineComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import {
    LabelLayout,
    UniversalTransition
} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
    CanvasRenderer
} from 'echarts/renderers';
import 'echarts/lib/component/dataZoom'
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    TransformComponent,
    GeoComponent,
    LineChart,
    BarChart,
    PieChart,
    ScatterChart,
    EffectScatterChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    MarkLineComponent
]);

// 接下来的使用就跟之前一样，初始化图表，设置配置项
/**
 * 
 * @param {* dom元素id } id 
 * @param {* 线条颜色 } color 
 * @param {* 年龄数组 } ages 
 * @param {* 真实状态数组 } data1 
 * @param {* 标准值 } data2 
 * @param {* 理论状态数组 } data3 
 */
var myCharts = []

function initBar(id,data) {
    var myChart = echarts.init(document.getElementById(id));

    var option = {

        legend: {},

        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:'10%',
            containLabel: true
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: data.title,
            axisLabel: {
                rotate: 270
            }
        },

        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '已完成',
                data: data.data2,
                stack: 'total',
                type: 'bar',
                itemStyle:{
                    color:'RGBA(146, 203, 118, 1)'
                },
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 8
                },
            },
            {
                name: '未完成',
                data: data.data1,
                type: 'bar',
                stack: 'total',
                itemStyle:{
                    color:'RGBA(250, 200, 88, 1)'
                },
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 8,
                },
            }
        ]
    }
    myChart.setOption(option, true)
    myCharts.push(myChart)
}

function initBarz(id,data) {
    var myChart = echarts.init(document.getElementById(id));

    var option = {

        legend: {},

        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:'10%',
            containLabel: true
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: data.title,
            axisLabel: {
                rotate: 270
            }
        },
        yAxis: {
            type: 'value',
            axisLabel:{
                formatter:function(res){
                    console.log('测试字段==>',res);
                    return res + '%'
                }
            }
            
        },
        series: [
            {
                name: '正常',
                data: data.data2,
                stack: 'total',
                type: 'bar',
                itemStyle:{
                    color:'RGBA(146, 203, 118, 1)'
                },
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 8,
                    formatter : function(params, ticket, callback){
                        return params.value + '%'
                    }
                },
            },
            {
                name: '筛查性近视',
                data: data.data1,
                type: 'bar',
                stack: 'total',
                itemStyle:{
                    color:'RGBA(250, 200, 88, 1)'
                },
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 8,
                    formatter : function(params, ticket, callback){
                        return params.value + '%'
                    }
                },
            }
        ]
    }
    myChart.setOption(option, true)
    myCharts.push(myChart)
}

function initBar3(id,data) {
    var myChart = echarts.init(document.getElementById(id));

    var option = {

        legend: {},

        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:'10%',
            containLabel: true
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: data.title,
            axisLabel: {
                rotate: 270
            }
        },

        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '正常视力',
                data: data.data1,
                stack: 'total',
                type: 'bar',
                itemStyle:{
                    color:'rgba(145,204,117,1.000)'
                },
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 8
                },
            },
            {
                name: '边缘视力',
                data: data.data2,
                type: 'bar',
                stack: 'total',
                itemStyle:{
                    color:'rgba(84,112,198,1.000)'
                },
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 8
                },
            },
            {
                name: '轻度不良',
                data: data.data3,
                type: 'bar',
                stack: 'total',
                itemStyle:{
                    color:'rgba(250,244,88,1.000)'
                },
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 8
                },
            },
            {
                name: '中度不良',
                data: data.data4,
                type: 'bar',
                stack: 'total',
                itemStyle:{
                    color:'rgba(250,160,88,1.000)'
                },
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 8
                },
            },
            {
                name: '重度不良',
                data: data.data5,
                type: 'bar',
                stack: 'total',
                itemStyle:{
                    color:'rgba(238,102,102,1.000)'
                },
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 8
                },
            }
        ]
    }
    myChart.setOption(option, true)
    myCharts.push(myChart)
}

function initPie(id,data) {
    var myChart = echarts.init(document.getElementById(id));
    let a = 0
    for(let i = 0;i < data.length;i++){
        a += Number(data[i].value)
    }
    var option = {
        tooltip: {
            formatter : function(params, ticket, callback){
                console.info('tttest==>',params);
                if(a == 0){
                    return '0%'
                }else{
                    return params.name + ":" + ((Number(params.value)/a)*100).toFixed(2) + '%'
                }
            }
        },
        legend: {
            bottom: '0%',
        },
        series: [
            {
                name: '全区视力异常率',
                type: 'pie',
                radius: ['20%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    formatter : function(params, ticket, callback){
                        if(a == 0){
                            return '0%'
                        }else{
                            return params.name + ":" + ((Number(params.value)/a)*100).toFixed(2) + '%'
                        }
                    }
                },
                data: data
            }
        ]
    };
    myChart.setOption(option, true)
    myCharts.push(myChart)
}

function initBar2(id,data) {
    var myChart = echarts.init(document.getElementById(id));
    for (let i = 0; i < data.data.length; i++) {
        data.data[i]['label'] = {
            show:true,
            fontSize:8
            // formatter : function(params, ticket, callback){
            //     return params.name + ":" + ((Number(params.value)/a)*100).toFixed(2) + '%'
            // }
        }
        
    }
    var option = {

        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:'20%',
            containLabel: true
        },
        legend: {},
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: data.title
        },
        yAxis: {
            type: 'value'
        },
        series: data.data
    };
    myChart.setOption(option, true)
    myCharts.push(myChart)
}
function initBar4(id,data) {
    var myChart = echarts.init(document.getElementById(id));

    var option = {

        legend: {

        },

        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top:'10%',
            containLabel: true
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: 'category',
            data: data.title,
            axisLabel: {
                rotate: 270
            }
        },

        yAxis: {
            type: 'value',
            axisLabel:{
                formatter:function(res){
                    console.log('测试字段==>',res);
                    return res + '%'
                }
            }
            
        },
        series: [
            {
                name: '正常',
                data: data.data2,
                stack: 'total',
                type: 'bar',
                itemStyle:{
                    color:'RGBA(146, 203, 118, 1)'
                },
                label:{
                    show:true,
                    fontSize:8,
                    formatter : function(params, ticket, callback){
                        return params.value + '%'
                    }

                }
            },
            {
                name: '筛查性近视',
                data: data.data1,
                type: 'bar',
                stack: 'total',
                itemStyle:{
                    color:'RGBA(250, 200, 88, 1)'
                },
                label:{
                    show:true,
                    fontSize:8,
                    formatter : function(params, ticket, callback){
                        return params.value + '%'
                    }
                }
            }
        ]
    }
    myChart.setOption(option, true)
    myCharts.push(myChart)
}

function initLine(id,data) {
    var myChart = echarts.init(document.getElementById(id));
    let sumArr = []
    for (let i = 0; i < data.data.length; i++) {
        sumArr = data.data[i].data.reduce((param,data1,index1)=>{
            if(param[index1]){
                param[index1] += Number(data1) 
            }else{
                param[index1] = Number(data1)
            }
            return param
        },sumArr)
        console.log(`sumArr==>${sumArr}`);
        data.data[i]['label'] = {
            show:true,
            formatter : function(params){
                if( !params.data || !sumArr[params.dataIndex]) return '0%'
                return ((Number(params.data) / sumArr[params.dataIndex]) * 100).toFixed(2) +'%'
            }
        };
    }
    console.log(data);
    var option = {
        
        tooltip: {
            trigger: 'axis',
            formatter : function(params){
                let a = params.reduce((a,b,c)=>{
                    if(!(+b.data) || !sumArr[b.dataIndex]){
                        a+=`<div>${b.seriesName}:0%<div><br>`
                        return a
                    }
                    a += '<div>'+b.seriesName+':'+((Number(b.data) / sumArr[b.dataIndex]) * 100).toFixed(2)+'%</div><br>'
                    return a 
                },'')
                return a 
            }
        },
        legend: {
            top: '2%',
        },
        grid: {
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.title
        },
        yAxis: {
            type: 'value'
        },
        series: data.data
    };
    myChart.setOption(option, true)
    myCharts.push(myChart)
}

window.onresize = function () {
    for (let i = 0; i < myCharts.length; i++) {
        myCharts[i].resize();
    }
}

export {
    initBar,
    initPie,
    initBar2,
    initLine,
    initBar3,
    initBar4,
    initBarz
};